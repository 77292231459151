import React from "react"

import Chess from "../MainComponents/Chess/Chess"

function Projects() {
    return (
        <Chess />
    )
}

export default Projects