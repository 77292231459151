import React from "react"

function Footer() {
    return (
        <div className="Footer">
            <div id="email">[MyDomainName] at gmail</div>
            © 2024 Jarred Higgs
        </div>
    )
}

export default Footer
