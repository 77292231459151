import React from "react"

const Home = (props) => {
    return (
        <div style={{ whiteSpace: "pre-line" }}>
        <p>
            <strong>Full Stack Developer</strong><br/><br/>
            <strong>Cloud Solutions Architect</strong><br /><br/>
            <strong>iOS Application Developer</strong><br/><br/>
        </p><br/>
        <p>
        </p>
        </div>
    )
}

export default Home